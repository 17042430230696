import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from '../../config/api'
import { getQueryStringParams } from '../../utils/filters';

window.WKI_APP = {}


const initialState = {
	loadingTopics: false,
	loadingSubTopics:false,
	status: 'init',
	data: {
		topics:''
	},
	data2: {
		subtopics:''
	},
	message: '',
	message_autologin: '',
	errors: {

	},
};



export const getTopic = createAsyncThunk(
	'GET_TOPIC_REQUEST',
	async (params) => {

		const { data } = await Api.get(`/channel/${params}`)
		if (!data) {
			throw new Error("No data");
		}
		return data
	}
)

export const getSubTopic = createAsyncThunk(
	'GET_SUBTOPIC_REQUEST',
	async (params) => {
		const { data } = await Api.get(`/channel/${params.channelcode}/topic/${params.topic_code}`)
		if (!data) {
			throw new Error("No data");
		}
		return data
	}
)

export const channelSlice = createSlice({
	name: "channelData",
	initialState,
	extraReducers: (builder) => {
		builder
			.addCase(getTopic.pending, (state) => {
				state.loadingTopics = true;
				state.status = 'pending'
				state.message = ''
			})
			.addCase(getTopic.fulfilled, (state, action) => {

				state.loadingTopics = false;
				state.status = 'fulfilled';
				state.message = action.payload.message
				state.data = action.payload.result
				localStorage.setItem('topics', JSON.stringify(state.data));
			})
			.addCase(getTopic.rejected, state => {
				state.loadingTopics = false;
				state.status = "rejected"
				state.message = "Error here at rejected"
			})
			.addCase(getSubTopic.pending, (state) => {
				state.loadingSubTopics = true;
				state.status = 'pending'
				state.message = ''

			})
			.addCase(getSubTopic.fulfilled, (state, action) => {

				// if (action.payload.status === 'success') {
				// 	let redirect_after = getQueryStringParams('rel');
				// 	if (redirect_after) {
				// 		window.location.href = redirect_after
				// 	} else {
				// 		window.location.href = window.location.origin + '/dashboard'
				// 	}
				// }
				state.loadingSubTopics = false;
				state.status = 'fulfilled';
				state.message = action.payload.message
				state.data2 = action.payload.result
				localStorage.setItem('subtopics', JSON.stringify(state.data2));
			})
			.addCase(getSubTopic.rejected, state => {
				state.loadingSubTopics = false;
				state.status = "rejected"
				state.message = "Error here at rejected"
			})
	}
})


export const getTopics = (state) => {
	return state.channel.data?.topics
}

export const getSubTopics = (state) => {
	return state.channel.data2?.subtopics
}

export const getLoadingTopics = (state) => {
	return state.channel.loadingTopics
}
export const getLoadingSubTopics = (state) => {
	return state.channel.loadingSubTopics
}

export const getStatus = (state) => {
	return state.channel.status
}

export default channelSlice.reducer;
