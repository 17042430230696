import React, { useEffect } from 'react';

import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';

import HomeContainer from './containers/HomeContainer';
import TopicContainer from './containers/TopicContainer';
import ErrorContainer from './containers/ErrorContainer.js';
import FormContainer from './containers/FormContainer.js';
import ExperimentsContainer from './containers/ExperimentsContainer.js';
import ThanksPageContainer from './containers/ThanksPageContainer.js'

import { Routes, Route} from "react-router-dom";
import { Layouts } from './layouts/Index';

import { useDispatch } from 'react-redux';
import { getLookupsAsync, CHANNELS } from './redux/reducers/lookups.js';

import RouteNames from './config/routes';

function App(props) {

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getLookupsAsync({
			lk: [CHANNELS]
		}))
	}, []);

	return (
		<Routes>
			<Route
				exact={true}
				path={RouteNames.main.path}
				element={<PublicRoute layout={Layouts.home} component={HomeContainer} />}
			/>
			<Route
				path={RouteNames.home.path}
				element={<PublicRoute title={RouteNames.home.title} layout={Layouts.home} component={HomeContainer} />}
			/>
			<Route
				path={RouteNames.experiments.path}
				element={<PublicRoute title={RouteNames.experiments.title} layout={Layouts.home} component={ExperimentsContainer} />}
			/>
			<Route
				path={RouteNames.channel_topic.path}
				element={<PublicRoute {...RouteNames.channel_topic} title={RouteNames.channel_topic.title} layout={Layouts.default} component={TopicContainer} />}
			/>
			<Route
				path={RouteNames.channel_topic_title.path}
				element={<PublicRoute {...RouteNames.channel_topic_title} title={RouteNames.channel_topic_title.title} layout={Layouts.default} component={TopicContainer} />}
			/>
			<Route
				path={RouteNames.channel_subtopic_title.path}
				element={<PublicRoute {...RouteNames.channel_subtopic_title} title={RouteNames.channel_subtopic_title.title} layout={Layouts.default} component={TopicContainer} />}
			/>
			<Route
				path={RouteNames.landing_channel.path}
				element={<PublicRoute {...RouteNames.landing_channel} title={RouteNames.landing_channel.title} layout={Layouts.default} component={TopicContainer} />}
			/>
			<Route
				path={RouteNames.landing_subtopic.path}
				element={<PublicRoute {...RouteNames.landing_subtopic} title={RouteNames.landing_subtopic.title} layout={Layouts.default} component={TopicContainer} />}
			/>
			<Route
				path={RouteNames.landing_topic.path}
				element={<PublicRoute {...RouteNames.landing_topic} title={RouteNames.landing_topic.title} layout={Layouts.default} component={TopicContainer} />}
			/>
			<Route
				path={RouteNames.topic_form.path}
				element={<PublicRoute {...RouteNames.topic_form} title={RouteNames.topic_form.title} layout={Layouts.default} component={FormContainer} />}
			/>
			<Route
				path={RouteNames.subtopic_form.path}
				element={<PublicRoute {...RouteNames.subtopic_form} title={RouteNames.subtopic_form.title} layout={Layouts.default} component={FormContainer} />}
			/>
			<Route
				path={RouteNames.landing_topic_form.path}
				element={<PublicRoute {...RouteNames.landing_topic_form} title={RouteNames.landing_topic_form.title} layout={Layouts.default} component={FormContainer} />}
			/>

			<Route
				path={RouteNames.landing_subtopic_form.path}
				element={<PublicRoute {...RouteNames.landing_subtopic_form} title={RouteNames.landing_subtopic_form.title} layout={Layouts.default} component={FormContainer} />}
			/>
			<Route
				path={RouteNames.landing_thanks_page.path}
				element={<PublicRoute {...RouteNames.landing_thanks_page} title={RouteNames.landing_thanks_page.title} layout={Layouts.default} component={ThanksPageContainer} />}
			/>
			<Route
				path={RouteNames.landing_thanks_page_topic.path}
				element={<PublicRoute {...RouteNames.landing_thanks_page_topic} title={RouteNames.landing_thanks_page_topic.title} layout={Layouts.default} component={ThanksPageContainer} />}
			/>
			<Route
				path={RouteNames.thanks_page.path}
				element={<PublicRoute {...RouteNames.thanks_page} title={RouteNames.thanks_page.title} layout={Layouts.default} component={ThanksPageContainer} />}
			/>
			<Route
				path={RouteNames.thanks_page_topic.path}
				element={<PublicRoute {...RouteNames.thanks_page_topic} title={RouteNames.thanks_page_topic.title} layout={Layouts.default} component={ThanksPageContainer} />}
			/>
			<Route path="*" element={<PublicRoute layout={Layouts.desktop} component={ErrorContainer} />} />
		</Routes>
	)
}

export default App;


