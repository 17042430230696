import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import BreadcrumbMui from '@mui/material/Breadcrumbs';
import { styled } from "@mui/material/styles";
import ButtonBase from "@mui/material/ButtonBase";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import { getLookupsAsync, getLookups, CHANNELS } from '../../redux/reducers/lookups.js';
import { getTopics, getSubTopics, getLoadingTopics, getLoadingSubTopics } from '../../redux/reducers/auth.js';



import RouteNames from '../../config/routes';

export default function Breadcrumbs({ name, reducer, reducerSingle }) {
	const params = useParams()
	const navigate = useNavigate()

	let location = useLocation();
	let pathname = location.pathname

	let checkPath = pathname.split('/')

	const onNavigate = (name, path) => {
		let newPath = path
		if (newPath === '/') newPath = ''
		navigate(newPath)
		if (step === '3')
			localStorage.setItem('step', step - 1)
		switch (name) {
			case `${channelTitle}`:
				return localStorage.removeItem('topicName')
			case `${topicTitle}`:
				return localStorage.removeItem('subtopicName')
			default: return null
		}


	}

	const replacePath = (rpathname) => {
		if (!rpathname) return ''
		Object.keys(params).map((key) => rpathname = rpathname.replace(":" + key, params[key]))
		return rpathname
	}

	const titlePage = useSelector((state) => {
		if (state && state[reducer] && state[reducer][reducerSingle] && state[reducer][reducerSingle]) {
			switch (reducerSingle) {
				case 'singleChannel':
					if (state[reducer][reducerSingle].channels) {
						let titles = state[reducer][reducerSingle].channels
						return `${titles[0].title}`
					}
				default:
					if (state[reducer][reducerSingle].title) {
						return `${name}: ${state[reducer][reducerSingle].title}`
					}
			}
		}
		return ""
	})

	const findMatches = () => {
		let matches = []

		let paths = pathname.split('/').filter(f => f != '')
		let pathsToPop = pathname.split('/').filter(f => f != '')

		let joinpaths = "/" + pathsToPop.join('/')
		let matchCodes = []
		paths.map((element, i) => {
			Object.keys(RouteNames).map((f) => {
				let pr = replacePath(RouteNames[f].path)
				if (pr == joinpaths && !matchCodes.includes(pr)) {
					matchCodes.push(pr)
					if (RouteNames[f].placeholder && i === 0) {
						matches.push({
							name: RouteNames[f].placeholder,
							path: ""
						})
					} else {
						matches.push({ ...RouteNames[f], path: pr })
					}
				}
			})

			pathsToPop.pop()
			joinpaths = "/" + pathsToPop.join('/')
		})

		let defaultMatches = { name: 'Home', path: '/home' }
		let errorMatches = { name: 'Error 404', path: '' }

		if (params['*']) {
			matches = []
			matches.push(defaultMatches)
			matches.push(errorMatches)
			return matches
		}

		if (matches.length == 0) {
			matches = []
			matches.push(defaultMatches)
			return matches
		}

		return matches.reverse()
	}

	const Img = styled("img")({
		margin: "auto",
		display: "block",
		maxWidth: "70%",
		maxHeight: "70%"
	});

	const items = findMatches()

	const path = window.location.pathname
	const step = localStorage.getItem('step')

	const onClickNavigate = () => {
		navigate('/home')
	}

	const channels = useSelector(getLookups)
	const topics = useSelector(getTopics)
	const subtopics = useSelector(getSubTopics)
	const loadingTopics = useSelector(getLoadingTopics)
	const loadingSubTopics = useSelector(getLoadingSubTopics)

	const channelTitle = channels.length != 0 && params.channelcode ? channels?.find((channel) => channel.code === params.channelcode).title : null
	const topicTitle = !loadingTopics && topics.length != 0 && params.topic_code ? topics?.find((topic) => topic.code === params.topic_code).title : null
	const subtopicTitle = !loadingSubTopics && subtopics.length != 0 && params.subtopic_code ? subtopics?.find((subtopic) => subtopic.code === params.subtopic_code).title : null

	const channelSubTitle = channels.length != 0 && params.channelcode ? channels?.find((channel) => channel.code === params.channelcode).subtitle : null
	const topicSubTitle = !loadingTopics && topics.length != 0 && params.topic_code ? topics?.find((topic) => topic.code === params.topic_code).subtitle : null
	const subtopicSubTitle = !loadingSubTopics && subtopics.length != 0 && params.subtopic_code ? subtopics?.find((subtopic) => subtopic.code === params.subtopic_code).subtitle : null

	return (
		<BreadcrumbMui aria-label="breadcrumb" sx={{ height: 'auto' }}>
			<Grid >
				<Grid item sm container >
					{!checkPath.includes('landing') ?
						<>
							<Tooltip title={`Torna alla Home`} placement="top">
								<Typography sx={{ cursor: 'pointer', color: '#3e78ff', fontSize: '18px', fontWeight: 300 }} onClick={onClickNavigate} >{'Home'} <span style={{ color: 'gray' }}>{'>'}&nbsp;</span></Typography>
							</Tooltip>
						</>
						: null
					}
					{channelTitle && !topicTitle && !subtopicTitle ?
						<Typography sx={{ cursor: 'pointer', fontSize: '18px', color: checkPath.includes('landing') ? '#3e78ff' : 'gray', fontWeight: 300 }}
							onClick={() => { onNavigate(channelTitle, checkPath.includes('landing') ? `/landing/${params.channelcode}` : `/home/${params.channelcode}`) }} >
							{channelTitle}
						</Typography>
						: channelTitle && topicTitle && !subtopicTitle ?
							<>
								<Tooltip title={`Hai scelto il "${channelSubTitle}", Clicca per cambiare la tua scelta`} placement="top">
									<Typography sx={{ cursor: 'pointer', color: '#3e78ff', fontSize: '18px', fontWeight: 300 }}
										onClick={() => { onNavigate(channelTitle, checkPath.includes('landing') ? `/landing/${params.channelcode}` : `/home/${params.channelcode}`) }}>
										{channelTitle}
									</Typography>
								</Tooltip>
								<Typography sx={{ fontSize: '18px', fontWeight: 300 }}>&nbsp;<span style={{ color: 'gray' }}>{'>'}&nbsp;</span>{topicTitle}</Typography>
							</>
							: channelTitle && topicTitle && subtopicTitle ?
								<>
									<Tooltip title={`Hai scelto il "${channelSubTitle}", clicca per cambiare la tua scelta`} placement="top">
										<Typography sx={{ cursor: 'pointer', color: '#3e78ff', fontSize: '18px', fontWeight: 300 }}
											onClick={() => {
												onNavigate(channelTitle, checkPath.includes('landing') ? `/landing/${params.channelcode}` :
													`/home/${params.channelcode}`)
											}}>
											{channelTitle}<span style={{ color: 'gray' }}>&nbsp;{'>'}&nbsp;</span>
										</Typography>
									</Tooltip>
									<Tooltip title={`Hai scelto l'argomento "${topicTitle}", clicca per cambiare la tua scelta`} placement="top">
										<Typography sx={{ cursor: 'pointer', color: '#3e78ff', fontSize: '18px', fontWeight: 300 }}
											onClick={() => {
												onNavigate(topicTitle, checkPath.includes('landing') ? `/landing/${params.channelcode}/${params.topic_code}`
													: `/home/${params.channelcode}/${params.topic_code}`)
											}} >
											{topicTitle}&nbsp;<span style={{ color: 'gray' }}>{'>'}&nbsp;</span>
										</Typography>
									</Tooltip>
									<Typography sx={{ fontSize: '18px', fontWeight: 300 }}>{subtopicTitle}</Typography>
								</>
								: null
					}
				</Grid>
			</Grid>
		</BreadcrumbMui>
	)
}
