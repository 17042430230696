import React from 'react'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '../components/Paper/index.js'
import { useSelector } from 'react-redux';
import { getLookups, getLoading} from '../redux/reducers/lookups.js';
import { useNavigate} from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';


export default function HomeContainer(){
	// const params = useParams()
	// const dispatch = useDispatch();
	const navigate = useNavigate()
	const data = useSelector(getLookups)
	const loading = useSelector(getLoading)

	const handleClick = (value) => {
		if(value.code){
			navigate(`/home/${value.code}`)
		}
	}

	return (
		<Box sx={{ flexGrow: 1,maxWidth:'100%'}}>
			<Grid  direction="row"  container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 8 }}>
				{!loading ?
					<>
						{Array.from(Array(data?.length)).map((_, index) => (
							<>
								{data?.length > 0  && data[index]?.enabled && data[index]?.visible &&
									<Grid item xs={2} sm={8} md={4} key={data[index]?.id}>
											<Paper onClick={handleClick} id={data[index]?.id} code={data[index]?.code} title={data[index]?.title} description={data[index]?.description}/>
									</Grid>
								}
							</>

						))}
					</>
					: <Box sx={{ mt:20, width: '100%', display: "flex", justifyContent: "center"}}><CircularProgress /></Box>
				}
    		</Grid>
	  	</Box>
	)

}
