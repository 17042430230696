import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery';


const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%"
});

export default function Card(props) {
	const {id, code, title, description, urlImage, onClick } = props

	const navigate = useNavigate()

	const handleClick = () =>{
		onClick({title,code,id})
	}

	const maxWidth = useMediaQuery('(max-width:600px)')
	return (
		<Paper
			sx={{
				p: 2,
				margin: "auto",
				minWidth: 50,
				minHeight: 150,
				flexGrow: 1,
				backgroundColor: (theme) =>
				theme.palette.mode = "#f0f0f0",
				boxShadow : 'none',
				borderRadius: '0px'
			}}
		>
			<Grid container spacing={2}>
				<Grid item xs={12} sm container >
					<Grid item xs container direction="column" spacing={2}>
						<Grid item xs sx={{maxWidth:'100% !important'}}>
							<Button
								variant="outlined"
								color='inherit'
								sx={{fontSize:'16px' ,width:'100% !important',
									wordWrap:'anywhere', borderRadius:'0px',
									backgroundColor: 'white !important', borderColor: 'lightgray'
								}}
								onClick={handleClick}
							>
								{title}
								<ArrowForwardIosIcon
									color='primary'
									sx={{position: "absolute", right: 0, fontSize: maxWidth ? '1rem' : null}}
								/>
							</Button>
							<Typography variant="body2" gutterBottom sx={{ marginTop: 2}}>
								{description}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
}
