import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from '../../config/api'

export const CHANNELS = 'channels';


const initialState = {
	loading: false,
	loadingEmail:false,
	data2: {

	},
	data: {

	},
	singleChannel: {}
};


export const sendTickets = createAsyncThunk(
	'POST_FORM_VALUES',
	async (params) => {
		const { data } = await Api.post(`/ticket`, params)
		if (!data) throw new Error("No data");
		return data
	}
)
export const sendEmail = createAsyncThunk(
	'POST_EMAIL_VALUES',
	async (params) => {
		const { data } = await Api.post(`/ticket/validate-email/`, params)
		if (!data) throw new Error("No data");
		return data
	}
)

const formSlice = createSlice({
	name: "form",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
		.addCase(sendTickets.pending, (state) => {
			state.status = 'pending'
			state.loading = true
		})
		.addCase(sendTickets.fulfilled, (state, action) => {
			state.status = 'fulfilled';
			state.loading = false
		})
		.addCase(sendTickets.rejected, state => {
			state.status = "rejected"
			state.loading = false
		})
		.addCase(sendEmail.pending, (state) => {
			state.status = 'pending'
			state.loadingEmail = true
		})
		.addCase(sendEmail.fulfilled, (state, action) => {
			state.status = 'fulfilled';
			state.loadingEmail = false
			state.data2 = action.payload

		})
		.addCase(sendEmail.rejected, state => {
			state.status = "rejected"
			state.loadingEmail = false
		})
	}
})


export const getResponse = (state) => {
	return state.form.status
}

export const getLoading = (state) => {
	return state.form.loading
}

export const getEmailResponse = (state) => {
	return state.form.data2
}

export const getEmailLoading = (state) => {
	return state.form.loadingEmail
}


export default formSlice.reducer;
