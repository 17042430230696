import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	notifications: {
		message: "",
		variant: ""
	},
};

const Notifications = createSlice({
	name: "notifications",
	initialState,
	reducers: {
		showNotification: (state, action) => {
			state.notifications.message = action.payload.message
			state.notifications.variant = action.payload.variant
		},
		clearNotification: (state) => {
			state.notifications.message = ''
			state.notifications.variant = ''
		}
	},

});

export const { showNotification, clearNotification } = Notifications.actions;
export default Notifications.reducer;
