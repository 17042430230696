export function verifyCodiceFiscale(szCodFisc){

	let nTempNum;
	let nAppoNum;
	let i;
	let szTempCodFisc;
	let szLastChar;
	let szValued;
	let szInValued;

	if (szCodFisc.length<16) return false
	if (szCodFisc == "") return false;
	else {

		szCodFisc =szCodFisc.toUpperCase();
		szLastChar = szCodFisc.substr(szCodFisc.length - 1,1);
		szTempCodFisc =szCodFisc.substr(0,szCodFisc.length - 1);
		nTempNum = 0;
		i = 0;

	while (true) {
		szValued="B1A0KKPPLLC2QQD3RRE4VVOOSSF5TTG6UUH7MMI8NNJ9WWZZYYXX";
		// I DISPARI
		szInValued=szTempCodFisc.substr(i,1);
		nAppoNum = szValued.search(szInValued);
		nTempNum = nTempNum + (nAppoNum &  0x7FFE) / 2;
		i = i + 1;
		if (i>15)
			break;
		szValued="A0B1C2D3E4F5G6H7I8J9KKLLMMNNOOPPQQRRSSTTUUVVWWXXYYZZ";
		// I PARI
		szInValued=szTempCodFisc.substr(i,1);
		nAppoNum = szValued.search(szInValued);
		nTempNum =  nTempNum + (nAppoNum &  0x7FFE) / 2;
		i = i + 1;
	}

	nTempNum = nTempNum % 26; //mod
	szValued="ABCDEFGHIJKLMNOPQRSTUVWXYZ";
	szValued= szValued.substr(nTempNum, 1);

	if (szValued==szLastChar) return true;
		else return false;
	}
}
