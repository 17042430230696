import React from 'react';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import Switch from '@mui/material/Switch';
// import AutoComplete from './AutoComplete';
// import AutoCompleteAsync from './AutoCompleteAsync';
// import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import SelectField from './SelectField';
import CustomDay from './DataPicker';

const fieldType = {
	password: 'password',
	text: 'text',
	select: 'select',
	textarea: 'textarea',
	checkbox: 'checkbox',
	switch: 'switch',
	datapicker: 'datapicker',
	slots: 'slots',
	autocomplete: 'autocomplete',
	autocompleteasync: 'autocompleteasync',
	qrcode:'qrcode'
}

export default function Field(props) {
	const { fieldtype, type } = props
	let defaultProps={}

	if (type === "switch" ){
        //for type switch we dont want helperText and error
		const { helperText, error, ...res}=props
		defaultProps = {
			...res,
			size: 'small',
		}
	}
	else{
		defaultProps = {
			...props,
			size: 'small',
			sx:{
				width: '100% !important',
			},
			variant:"standard"
		}
	}

	switch (fieldtype || type) {
		case fieldType.text:
		case fieldType.textarea:
			return <TextField {...defaultProps} />
		case fieldType.password:
			return <TextField {...defaultProps} />
		case fieldType.select:
			return <SelectField {...defaultProps} />
		case fieldType.checkbox:
			return <Checkbox {...defaultProps} />
		case fieldType.radio:
			return <Radio {...defaultProps} />
		case fieldType.switch:
			return <FormControlLabel control={<Switch />} {...defaultProps} />
		case fieldType.datapicker:
			return <CustomDay {...defaultProps} />
		default:
			return null
	}
}
