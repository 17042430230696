import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

export default function SelectField(props) {

	const { options, labelId, label, size, onChange, name, value = "" } = props
	const { withLabelAll = false, clean = false, helperText, ...res} = props;
	let opt = withLabelAll ? [{ id: 'all', label: 'Tutti' }, ...options] : options;

	const onSelectChange = (e) => {
		let value = e.target.value === 'all' ? "" : e.target.value === 0 ? "0" : e.target.value
		onChange({
			target: {
				name: e.target.name,
				value: value
			}
		})
	}


	const clearSelectFilter = () => {
		const e = {
			target: {
				name: name,
				value: ""
			}
		}
		onChange(e);
	}

	const v = value === 0 ? "0" : value ? value : withLabelAll ? "all" : null

	return (
		<FormControl fullWidth size={size} error={helperText || false}>
			<InputLabel id={labelId}>{label}</InputLabel>
			{opt &&
				<Select {...res}
					sx={clean ? { "& .MuiSelect-iconOutlined": { display: value ? 'none' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'primary.main' } } : {}}
					endAdornment={clean ? <IconButton sx={{ visibility: value ? "visible" : "hidden" }} onClick={clearSelectFilter}><ClearIcon /></IconButton> : <></>}
					onChange={onSelectChange} value={v}
				>
					{opt.map((option) => (
						<MenuItem
							key={option.id}
							value={option.id}
						>
							{option.label}
						</MenuItem>
					))}
				</Select>
			}
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl >
	);
}
