import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from '../../config/api'

export const CHANNELS = 'channels';


const initialState = {
	loading: false,
	data: {
		[CHANNELS]: []
	},
	singleChannel: {}
};


export const getLookupsAsync = createAsyncThunk(
	'GET_LOOKUPS_REQUEST',
	async (params) => {
		const { data } = await Api.get(`/lookups`, params)
		if (!data) throw new Error("No data");
		return data
	}
)

const lookupsSlice = createSlice({
	name: "lookups",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getLookupsAsync.pending, (state) => {
				state.status = 'pending'
				state.loading = true
			})
			.addCase(getLookupsAsync.fulfilled, (state, action) => {
				state.status = 'fulfilled';
				state.loading = false
				state.data = action.payload.result
				localStorage.setItem('channel', JSON.stringify(state.data))
				state.singleChannel = action.payload.result
			})
			.addCase(getLookupsAsync.rejected, state => {
				state.status = "rejected"
				state.loading = false
			})
	}
})


export const getLookups = (state) => {
	return state.lookups.data.channels
}

export const getChannelTitle = (state) => {
	return state.lookups.singleChannel
}

export const getLoading = (state) => {
	return state.lookups.loading
}

export const getLookup = (name) => (state) => {
	return state.lookups.data[name]
}

export default lookupsSlice.reducer;
