import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationIT from "../src/assets/locales/it/translation.json";

const fallbackLng = ["it"];

const resources = {
  it: {
    translation: translationIT
  }
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true
    },

    debug: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
