import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import { ThemeProvider, styled } from '@mui/material/styles';
import theme from '../theme/index';
import Header from '../components/Header/Index';
import Container from '@mui/material/Container';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useDispatch } from 'react-redux';
import { getTopic, getSubTopic } from '../redux/reducers/auth.js';
import { getLookups, getLoading } from '../redux/reducers/lookups.js'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useParams } from 'react-router-dom'
import ErrorContainer from '../containers/ErrorContainer';

export default function DefaultLayout({ name, reducer, reducerSingle, children }) {
	const dispatch = useDispatch()
	const params = useParams()
	const channels = useSelector(getLookups)
	const loadingChannels = useSelector(getLoading)
	const isChrome = navigator.userAgent.indexOf('Chrome') !== -1;

	const path = window.location.href.split("/")
	const checkPath = path.includes('form')
	const checkPath2 = path.includes('summary')
	const channelcode = params.channelcode
	const enabled_channel = !loadingChannels && channels?.length > 0  ? channelcode && channels?.find((channel) => channel.code === channelcode).enabled : true


	useEffect(() => {
		if (channelcode) {
			dispatch(getTopic(channelcode))
		}
	}, [])

	const checkMaintenance = process.env.REACT_APP_MAINTENANCE

	const maxWidth = useMediaQuery('(max-width:729px)')
	const height = window.innerHeight + 350
	const height2 = window.innerHeight + 600

	return (

		<>
			<ThemeProvider theme={theme}>
				<Header />
				<Box >
					<Container component="main" sx={{ mb: 3, mt: 10, minHeight: '83vh' }}>
						{!enabled_channel ? <ErrorContainer /> :
							<>
								{checkMaintenance === 'false' ?
									<>
										<Typography sx={{ mt: 3, mb: 3, fontWeight: 500, fontSize: '21px', color: '#626161' }} gutterBottom>{checkPath2 ? 'Richiesta di assistenza inviata' : `Inserisci la tua richiesta di assistenza`}
											<Box sx={{ float: 'right' }}>
												<span cx="20" cy="20" r="20" style={{ fontSize: '16px', backgroundColor: 'rgb(12, 71, 183)', borderRadius: '15px', paddingInline: '9px', paddingBlock: isChrome ? '5px' : '4px', color: 'white' }}>1</span>&nbsp;&nbsp;&nbsp;
											<span cx="20" cy="20" r="20" style={{ fontSize: '16px', backgroundColor: checkPath ? 'rgb(12, 71, 183)' : '#29acfb', borderRadius: '15px', paddingInline: '9px', paddingBlock: isChrome ? '5px' : '4px', color: 'white' }}>2</span>&nbsp;&nbsp;&nbsp;
											{!checkPath2 ?
													<span cx="20" cy="20" r="20" style={{ fontSize: '16px', backgroundColor: checkPath ? '#29acfb' : '#9f9f9fd9', borderRadius: '15px', paddingInline: '9px', paddingBlock: isChrome ? '5px' : '4px', color: 'white' }}>3</span>
													:
													<span cx="20" cy="20" r="20" style={{ fontSize: '16px', backgroundColor: 'rgb(12, 71, 183)', borderRadius: '15px', paddingInline: '9px', paddingBlock: isChrome ? '5px' : '4px', color: 'white' }}>3</span>
												}
											</Box>
										</Typography>
										<Grid container > {children} </Grid>
									</>
									:
									<Typography sx={{ mt: 40, ml: 20, fontWeight: 500, fontSize: '21px' }} >
										{'Gentile utente,'}<br />
										{'il form web per contattare il nostro Servizio Clienti è momentaneamente in manutenzione.'}<br /><br />
										{'La preghiamo di utilizzare in alternativa i seguenti canali:'}<br /><br />
										{'email: servizio.clienti@wolterskluwer.com'}<br />
										{'telefono: (+39) 02 824761'}
									</Typography>
								}
							</>
						}
					</Container>
					<Footer />
				</Box>
			</ThemeProvider>
		</>
	)
}
