import React from 'react';
import Layouts  from '../layouts/Index';
import { Navigate } from 'react-router-dom';
import {getStatus,isAuthenticated} from '../redux/reducers/auth';
import {useSelector } from 'react-redux';

const PublicRoute = ({component: Component, restricted, layout, name, reducer, reducerSingle, ...props}) => {

	const status = useSelector(getStatus)
	let path = window.location.pathname

	if( status === 'fulfilled' && ['/login','/forgot-password','/reset-password'].includes(path) ){
		return <Navigate to={"/dashboard"} />
	}

	const Layout = layout ? Layouts[layout] : Layouts.Default

	return <Layout name={name} reducer={reducer} reducerSingle={reducerSingle} ><Component {...props} /></Layout>

};

export default PublicRoute;
