import * as React from 'react';
import { Dropzone } from "dropzone";
import FileUploadIcon from '@mui/icons-material/FileUpload';
const customMessage=
{
	display: "flex", 
	justifyContent: "center", 
	alignItems: "center", 
	background: "#85b3db4a", 
	padding: "8px", 
	borderRadius: "10px",
	fontWeight: "bold",
	cursor: "pointer"
}
export default function ExperimentsContainer (){
	const API = "https://www.mocky.io/v2/5cc8019d300000980a055e76"

		React.useEffect(()=>{
			if (document.getElementById('my-awesome-dropzone')) {
				const dropzone = new Dropzone("#my-awesome-dropzone", { url: API, chunking: true, chunkSize: 20000 });
			}
		}, [])
		
	return(
		<>
		< form action = "/file-upload"
			className="dropzone test"
			id = "my-awesome-dropzone" >
				<div className="dz-message" data-dz-message><span className="customMessage" style={customMessage}>Carica file<FileUploadIcon fontSize="small" sx={{ml: "3px"}}/></span></div>
		</form >
		</>
	)
}
