import { configureStore } from '@reduxjs/toolkit';
import NotificationsMiddleware from './middleware/Notifications';

import notifications from './reducers/notifications';
import auth from './reducers/auth';
import lookups from './reducers/lookups';
import form from './reducers/form';
import system from './reducers/system'

const composeEnhancers = {
	features: {
		pause: true,
		lock: true,
		persist: false,
		export: false,
		import: "custom",
		jump: false,
		skip: false,
		reorder: true,
		dispatch: true,
		test: false,
	},
};

export const store = configureStore({
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(NotificationsMiddleware),
	reducer: {
		notifications: notifications,
		lookups: lookups,
		channel: auth,
		system:system,
		form: form
	},
	devTools: composeEnhancers,
});
