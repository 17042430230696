import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import {getUser} from '../../redux/reducers/auth.js';
import { useDispatch, useSelector } from 'react-redux';
import Logo from './../Logo.js';
import MuiAppBar from '@mui/material/AppBar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


let drawerWidth = 240

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
		transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.easeOut,
		duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));


export default function Header({drawerWidth,open,onOpen,mode='default'}) {

	let xs = {
		width: `calc(100%)`,
		position: 'fixed',
		zIndex: 1100,
		top: '0px',
		left: 'auto',
		right: '0px'
	}
	const maxWidth = useMediaQuery('(max-width:440px)')
	const maxWidth2 = useMediaQuery('(max-width:680px)')

	return (
		<AppBar
			position="relative"
			open={open}
			sx={xs}
			color="white"


		>
			<Toolbar>
			{<Logo/> }
			{!maxWidth2 ?
				<Typography component="div" sx={{ flexGrow: 1, color:'grey', textAlign: 'right', fontWeight: 300, fontSize: !maxWidth ? '24px' : '15px' }}>
					{'Assistenza Clienti Wolters Kluwer Italia'}
				</Typography>
				:
				<Typography component="div" sx={{ flexGrow: 1, color:'grey', textAlign: 'right', fontWeight: 300, fontSize: !maxWidth ? '20px' : '15px' }}>
					{'Assistenza Clienti'} <br/>{'Wolters Kluwer Italia'}
				</Typography>
			}
			</Toolbar>
		</AppBar>
	);
}
//
