import React from 'react';
import MUIButton from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

const buttonTypes = {
    button: "button",
    loading: "loading",
}

export default function Button(props){

    const {subtopic_code, subtopic_id, topic_title, has_sons, buttontype, topic_id, onClick ,channel_id, topic_code} = props

	const defaultProps = {
		...props,
	}

	const handleClick = () =>{
		onClick({subtopic_code, subtopic_id, topic_title, topic_id, channel_id, has_sons, topic_code})
	}

	switch(buttontype){
		case buttonTypes.button:
			return <MUIButton style={{borderRadius:'0px'}} {...defaultProps} onClick={handleClick}></MUIButton>
		case buttonTypes.loading:
			return <LoadingButton style={{borderRadius:'0px'}} {...defaultProps} />
		default:
			return null
	}

}
