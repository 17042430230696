import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import useMediaQuery from '@mui/material/useMediaQuery';
import logo from '../../src/assets/images/logo/large.svg'


function Copyright() {
	const maxWidth2 = useMediaQuery('(max-width:546px)')
	return (
		<Typography color="text.primary" sx={{fontSize:!maxWidth2 ? '11px' : '9px', wordWrap:'anywhere'}} >
			<Link color="inherit" underline="hover" target="_blank" href="https://www.wolterskluwer.com/it-it/terms-of-use">
				{'Condizioni di utilizzo '}
			</Link>
			{" - "}
			<Link color="inherit" underline="hover" target="_blank" href="https://www.wolterskluwer.com/it-it/privacy-cookies">
				{'Privacy e Cookie '}
			</Link>&nbsp;&nbsp;&nbsp;
			<a style={{color:'inherit'}} href="javascript:UserDataTrust.openCookiebox();">{'Gestire i dettagli e le impostazioni dei cookie'}</a>
			<br/>
			{<span>&copy;</span>}
			{new Date().getFullYear()}
			{' Wolters Kluwer N.V.e/o sue consociate.Tutti i diritti riservati.'}
			<br/>
			<Link color="inherit" underline="hover" target="_blank" href="https://www.bluservice.it">
				{'Powered by Bluservice'}
			</Link>
		</Typography>
	)
}


const Footer = () => {
	const maxWidth = useMediaQuery('(max-width:890px)')
	const maxWidth2 = useMediaQuery('(max-width:546px)')

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: !maxWidth2 ? 'row' : 'column',
					alignItems:'center',
					verticalAlign: 'bottom',
					minHeight: '4rem',
					width: `calc(100%)`,
					position:'relative',
					bottom:'0',
					left: 'auto',
					right: '0px',
					backgroundColor: '#f0f0f0',
					borderTop:'2px solid #e3dfdf',
				}}
			>
				<br/>
				<Box sx={{width: 'auto', position:'relative', left: !maxWidth2 ? 25 : 1}} >
					<Copyright/>
				</Box>
				<Box sx={{maxWidth: !maxWidth2 ? '150px' : 'auto', position:!maxWidth2 ? 'absolute' : 'relative', right:!maxWidth2 ? 30 : -2}} >
					<img alt="" src={logo} style={{maxWidth:!maxWidth2 ? '150px' : '120px'}}/>
				</Box>

			</Box>
		</>
	)
}

export default Footer
