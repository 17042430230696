import Axios from 'axios'

import qs from "qs";

const defaultHeaders = {
	'X-Requested-With': 'XMLHttpRequest',
}

const baseURL = `${process.env.REACT_APP_BACKEND_URL}`

const defaultAxiosConfiguration = {
	baseURL: baseURL,
	headers: defaultHeaders,
	withCredentials: true,
}

const Api = {
	version: process.env.REACT_APP_BACKEND_API_VERSION,
	baseUrl: baseURL,
	headers: defaultHeaders,
	instance: Axios.create(defaultAxiosConfiguration),
	init: function () { },

	endpoint: function (request) {
		let url = ''
		switch (request) {
			default:
				url = `/api/${process.env.REACT_APP_BACKEND_API_VERSION}` + request
				break
		}
		return url
	},

	get: function (request, data = null) {

		let url = this.endpoint(request)

		this.init()

		if (data == null) {
			return this.instance.get(url)
		}
		return this.instance.get(url + '?&' + qs.stringify(data))
	},

	post: function (request, data = null) {
		let url = this.endpoint(request)
		this.init()

		if (data == null) {


			return this.instance.post(url)
		}
		return this.instance.post(url, data)
	},

	put: function (request, data = null) {
		let url = this.endpoint(request)
		this.init()

		if (data == null) {
			return this.instance.put(url)
		}
		return this.instance.put(url, data)
	},

	delete: function (request, data = null) {
		let url = this.endpoint(request)
		this.init()

		if (data == null) {
			return this.instance.delete(url)
		}
		return this.instance.delete(url, data)
	},
	sanctum: function (url = '') {
		return this.instance.get(url)
	},

	auth: function (data) {

		let url = this.endpoint('/auth')

		return Api.sanctum('/sanctum/csrf-cookie').then((response) => {
			if (response) {
				let headers = {
					'X-XSRF-TOKEN': response.config.headers['XSRF-TOKEN']
				}
				return Axios.create({
					...defaultAxiosConfiguration,
					headers: {
						...defaultAxiosConfiguration.headers,
						...headers
					}
				}).post(url, data).then((response) => {
					return response
				})
			} else {
				throw "Error something goes wrong"
			}
		})
	},
}

export default Api

/**
 * Example
 *
 * let data = {
 *  filters: {}
 * }
 * await Api.get('users',data)
 */
