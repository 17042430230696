import { showNotification } from "../reducers/notifications";

const AlertMiddleware = ({ dispatch, getState }) => next => action => {
	const { type } = action;

	const thunckStatus = type.split('/')[1]

	const actionRequest = type.split('_')[0]

	let actionFulfilledKeys = ['LOGIN', 'LOGOUT', 'CREATE', 'DELETE', 'UPDATE', 'POST', 'ADD', 'PUT']
	let ignoreActionRejectedKeys = ['GET']

	switch (thunckStatus) {
		case 'fulfilled': {
			if (actionFulfilledKeys.includes(actionRequest)) {
				const statusRequest = action.payload.status
				const messageRequest = action.payload.message
				let variant = '';
				if (statusRequest === 'success') variant = 'success'
				if (statusRequest === 'failed') variant = 'error'
				dispatch(showNotification({ message: messageRequest, variant: variant }));
			}
			break;
		}

		case 'rejected': {
			if (!ignoreActionRejectedKeys.includes(actionRequest)) {
				dispatch(showNotification({ message: "Error !", variant: "error" }));
			}
			break;
		}

		default: break;

	}

	next(action)
}

export default AlertMiddleware;
