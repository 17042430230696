import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	mobileMode : false
};

export const systemSlice = createSlice({
	name: "system",
	initialState,
	reducers: {
		mobileMode: (state, action) => {
			state.mobileMode = action.payload.mobileMode;
		}
	},
})

export const getMobileMode = (state) => {
	return state.system.mobileMode
}
export const { mobileMode } = systemSlice.actions;

export default systemSlice.reducer;


