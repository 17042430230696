import { createTheme } from '@mui/material/styles';

let cc = 1;

const combinations = [
	["#21A3A3", "#135757", "#00D3BA", "#207373"],
	["#477FF0", "#03379e", "#3e78ff", "#4141cc"],
	["#8D4BF2", "#4e05bc", "#8D4BF2", "#4e05bc"],
];

const theme = createTheme({
	height:'100%',
	palette: {
		white: {
			main: '#fff',
			darker: '#053e85',
		},
		primary: {
			main: combinations[cc][2],
			darker: '#053e85',
		},
		secondary: {
			main: combinations[cc][1],
			darker: '#053e85',
		},
		neutral: {
			main: '#64748B',
			contrastText: '#fff',
		},
		teal: {
			main: '#00695f',
			contrastText: '#fff',
		},
	},
	typography: {
		fontFamily: "Ubuntu, Roboto, Arial, sans-serif",
	},
	components: {
		// Name of the component
		MuiButton: {
			styleOverrides: {
				// Name of the slot
				root: {

				},
			},
		},
	}
});

export default theme
