import Default 	from './DefaultLayout';
import Home 	from './HomeLayout';

const Layouts = {
	default:'Default',
	home:'Home'
}

const LayoutComponents = {
	[Layouts.default]:Default,
	[Layouts.home]:Home
}

export {
	Layouts
}

export default LayoutComponents
