import i18n from "../i18Init"

const RouteNames = {
	main: {
		path: '/',
		name: "Home",
		title: "Home"
	},
	home: {
		path: '/home',
		name: "Home",
		title: "Home"
	},
	experiments: {
		path: '/experiments',
		name: "Home",
		title: "Home"
	},
	channel_topic: {
		path: '/home/:channelcode',
		name: "Home",
		title: "Home",
		reducer: 'lookups',
		reducerSingle: 'singleChannel',
	},
	channel_topic_title: {
		path: '/home/:channelcode/:topic_code',
		name: "Home",
		title: "Home",
		reducer: 'auth',
		reducerSingle: 'singleChannel',
	},
	channel_subtopic_title: {
		path: '/home/:channelcode/:topic_code/:subtopic_code',
		name: "Home",
		title: "Home",
		reducer: 'auth',
		reducerSingle: 'singleChannel',
	},
	landing_channel: {
		path: '/landing/:channelcode',
		name: "Home",
		reducer: 'auth',
		reducerSingle: 'singleChannel',
	},
	landing_topic: {
		path: '/landing/:channelcode/:topic_code',
		name: "Home",
		reducer: 'auth',
		reducerSingle: 'singleChannel',
	},
	landing_subtopic: {
		path: '/landing/:channelcode/:topic_code/:subtopic_code',
		name: "Home",
		reducer: 'auth',
		reducerSingle: 'singleChannel',
	},
	topic_form: {
		path: '/home/:channelcode/:topic_code/form',
		name: "Home",
		reducer: 'auth',
		reducerSingle: 'singleChannel',
	},
	subtopic_form: {
		path:'/home/:channelcode/:topic_code/:subtopic_code/form',
		name: "Home",
		reducer: 'auth',
		reducerSingle: 'singleChannel',
	},
	landing_topic_form: {
		path:'/landing/:channelcode/:topic_code/form',
		name: "Home",
		reducer: 'auth',
		reducerSingle: 'singleChannel',
	},
	landing_subtopic_form: {
		path:'/landing/:channelcode/:topic_code/:subtopic_code/form',
		name: "Home",
		reducer: 'auth',
		reducerSingle: 'singleChannel',
	},
	thanks_page: {
		path:'/home/:channelcode/:topic_code/:subtopic_code/form/summary',
		name: "Home",
		reducer: 'auth',
		reducerSingle: 'singleChannel',
	},
	thanks_page_topic: {
		path:'/home/:channelcode/:topic_code/form/summary',
		name: "Home",
		reducer: 'auth',
		reducerSingle: 'singleChannel',
	},
	landing_thanks_page: {
		path:'/landing/:channelcode/:topic_code/:subtopic_code/form/summary',
		name: "Home",
		reducer: 'auth',
		reducerSingle: 'singleChannel',
	},
	landing_thanks_page_topic: {
		path:'/landing/:channelcode/:topic_code/form/summary',
		name: "Home",
		reducer: 'auth',
		reducerSingle: 'singleChannel',
	}
}

export default RouteNames
