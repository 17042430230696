import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Box from '@mui/material/Box';
import Button from '../components/Button/Index'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {getTopics, getSubTopic, getSubTopics, getLoadingTopics, getLoadingSubTopics} from '../redux/reducers/auth.js';
import { useNavigate, useParams} from 'react-router-dom'
import Typography from '@mui/material/Typography';
import Breadcrumbs from '../components/Breadcrumbs/Index';




export default function ThanksPageContainer(props){


	const dispatch = useDispatch();
	const navigate = useNavigate()
	const params = useParams()
	const channelcode = params.channelcode
	const topic_code = params.topic_code
	const subtopic_code = params.subtopic_code
	const topics =  useSelector(getTopics)
	const subtopics =  useSelector(getSubTopics)
	const loadingTopics = useSelector(getLoadingTopics)
	const loadingSubTopics = useSelector(getLoadingSubTopics)

	const topic_has_number_order = !loadingTopics && topics.length != 0  && params.topic_code ? topics?.find((topic)=> topic.code === params.topic_code ).has_number_order : null
	const topic_has_codice_cliente = !loadingTopics && topics.length != 0  && params.topic_code ? topics?.find((topic)=> topic.code === params.topic_code ).has_codice_cliente : null
	const subtopic_has_codice_cliente = !loadingSubTopics && subtopics.length != 0  && params.subtopic_code ?
		subtopics?.find((subtopic)=> subtopic.code === params.subtopic_code).has_codice_cliente : null

	useEffect(() => {
		window.scrollTo(0, 0)
		if(channelcode && topic_code){
			dispatch(getSubTopic({topic_code, channelcode}))
		}
		if(channelcode && topic_code && subtopic_code){
			localStorage.setItem('subtopic_code', subtopic_code);
		}
	}, [params])

	const summaryValues = JSON.parse(localStorage.getItem('allSendValues'))
	const files = JSON.parse(localStorage.getItem('filesName'))

	const path = window.location.href.split("/")
	let checkPath = path.includes('landing')

	function onClickExit(){
		localStorage. clear()
		window.location.href = checkPath ? `/landing/${channelcode}` : '/home'
	}

	function onClickNewRequest(){
		localStorage.removeItem('allSendValues', 'channel', 'filesName', 'subtopics', 'topics')
		window.location.href = checkPath ? `/landing/${channelcode}` : '/home'
	}

	return (
		<Box sx={{ flexGrow: 1}}>
			<Breadcrumbs/>
			<Typography sx={{mt:3, fontWeight:400, fontSize: '18px', color:'#484747' }}>
				{`Ti ringraziamo per aver utilizzato questo servizio di assistenza.`}<br/>
				{`La tua richiesta è stata registrata e verrà al più presto presa in carico dal nostro Servizio
				  Clienti, che ti risponderà nel più breve tempo possibile.`}<br/>
				{`Di seguito ti riepiloghiamo i dati da te inviati.`}
			</Typography><br/>
			{(topic_has_codice_cliente || subtopic_has_codice_cliente) && summaryValues.customer_code != '' ?
				<Typography>
					<span style={{fontSize:'19px',fontWeight:'600'}}>{'Codice cliente:  '}</span>{`${summaryValues.customer_code}`}<br/>
				</Typography>
				: null
			}
			{topic_has_number_order && summaryValues.shop_order_number != '' ?
				<Typography>
					<span style={{fontSize:'19px',fontWeight:'600'}}>{'Numero Ordine Shop:  '}</span>{`${summaryValues.shop_order_number}`}<br/>
				</Typography>
				: null
			}
			{!topic_has_number_order && summaryValues.codice_fiscale_partita_iva != '' ?
				<Typography>
					<span style={{fontSize:'19px',fontWeight:'600'}}>{'Codice Fiscale/Partita Iva:  '}</span>{`${summaryValues.codice_fiscale_partita_iva}`}<br/>
				</Typography>
				: null
			}
			{summaryValues.fullname != '' ?
				<Typography>
					<span style={{fontSize:'19px',fontWeight:'600'}}>{'Denominazione:  '}</span>{`${summaryValues.fullname}`}<br/>
				</Typography>
				: null
			}
			{summaryValues.email != '' ?
				<Typography>
					<span style={{fontSize:'19px',fontWeight:'600'}}>{'Email:  '}</span>{`${summaryValues.email}`}<br/>
				</Typography>
				: null
			}
			{summaryValues.telephone != '' ?
				<Typography>
					<span style={{fontSize:'19px',fontWeight:'600'}}>{'Telefono:  '}</span>{`${summaryValues.telephone}`}<br/>
				</Typography>
				: null
			}
			{summaryValues.abstract != '' ?
				<Typography>
					<span style={{fontSize:'19px',fontWeight:'600'}}>{'Testo della richiesta:  '}</span><br/>{`${summaryValues.abstract}`}<br/>
				</Typography>
				: null
			}
			{files.length != 0 ?
				<>
					<Typography>
						<span style={{fontSize:'19px',fontWeight:'600'}}>{'Allegati:  '}</span>
					</Typography>
					{Array.from(Array(files?.length)).map((_, index) => (
						<>
							{files?.length > 0  &&
								<Typography>
									<span>&nbsp;&nbsp;&nbsp;&nbsp;</span><span style={{fontSize:'22px'}}>&#8901;</span>{`${files[index]}`}
								</Typography>
							}
						</>

					))}
				</>
				: null
			}
			<Grid container spacing={3} >
				<Grid item xs={6}>
					<Button onClick={onClickExit} buttontype='loading' variant="contained" fullWidth size='large'
						sx={{mt:5, backgroundColor:'#007ac3'}}
					>
						{'Chiudi'}
					</Button>
				</Grid>
				<Grid item xs={6}>
					<Button onClick={onClickNewRequest} buttontype='loading' variant="contained" fullWidth size='large'
						sx={{mt:5, backgroundColor:'#007ac3'}}
					>
						{'Inserisci Nuova Richiesta'}
					</Button>
				</Grid>
			</Grid>

		</Box>
	)

}
