import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Box from '@mui/material/Box';
import Button from '../components/Button/Index'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {getTopics, getSubTopic, getSubTopics, getLoadingTopics, getLoadingSubTopics} from '../redux/reducers/auth.js';
import {getLookups} from '../redux/reducers/lookups.js'
import { useNavigate, useParams} from 'react-router-dom'
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Breadcrumbs from '../components/Breadcrumbs/Index';




export default function TopicContainer(props){


	const dispatch = useDispatch();
	const navigate = useNavigate()
	const [active, setActive] = useState()
	const path = window.location.href.split("/")
	let checkPath = path.includes('landing')

	const params = useParams()

	const channelcode = params.channelcode
	const topic_code = params.topic_code
	const subtopic_code = params.subtopic_code

	const channels = useSelector(getLookups)
	const data = useSelector(getTopics)
	const subTopics = useSelector(getSubTopics)
	const loadingTopics = useSelector(getLoadingTopics)
	const loadingSubTopics = useSelector(getLoadingSubTopics)
	const subTopicsStorage = JSON.parse(localStorage.getItem('subtopics'))
	const topic_id = !loadingTopics && data.length != 0  && params.topic_code ? data?.find((topic)=> topic.code === params.topic_code ).id : null
	const topic_Code = !loadingTopics && data.length != 0  && params.topic_code ? data?.find((topic)=> topic.code === params.topic_code ).code : null
	const headerText = channels.length != 0  && channelcode ? channels?.find((channel)=> channel.code === channelcode ).headertext : null


	useEffect(() => {
		if(channelcode && topic_code){
			dispatch(getSubTopic({topic_code, channelcode}))
		}
		if(path.length < 6) setActive()
		if(topic_code === topic_Code) setActive(topic_id)

		localStorage.removeItem("subtopic_id")

	}, [params])

	useEffect(() => {
		if(path.length < 6) setActive()
		if(topic_code === topic_Code) setActive(topic_id)

	}, [data])

	const handleClickTopic = (value) => {
		setActive(value.topic_id)

		let topic_code = value.topic_code

		if(!value.has_sons){
			navigate(!checkPath ? `/home/${params.channelcode}/${topic_code}/form` : `/landing/${params.channelcode}/${topic_code}/form`)
		}else{
			navigate(!checkPath ? `/home/${params.channelcode}/${topic_code}` : `/landing/${params.channelcode}/${topic_code}`)
		}

	}
	const handleClickSubTopic = (value) => {
		let subtopic_code = value.subtopic_code

		navigate(`${subtopic_code}/form`)
	}

	return (
		<Box sx={{ flexGrow: 1,maxWidth:'100%'}}>
			<Breadcrumbs/>
			<Typography sx={{ mt: 5, fontWeight:300, fontSize: '18px', color:'#484747' }}>{headerText}</Typography>
			<br/>
			{!loadingTopics ?
				<Grid container spacing={10} columns={{ xs: 4, sm: 8, md: 8 }}>
					{data[0]?.channel_id === 1 ?
						<>
							{data && data?.map(item => (
								<Grid item xs={2} sm={8} md={4} key={item.id}>
									<Button key={item.id} onClick={handleClickTopic} has_sons={item?.has_sons} topic_title={item?.title}
										topic_id={item?.id} topic_code={item?.code}  channel_id={item?.channel_id} buttontype='button' variant="outlined" size="large"
										sx={{justifyContent:'left !important', wordWrap:'anywhere', minWidth:'100%',fontSize:'18px', marginBottom:'10px', color: item.id === active ? '#007ac3' : null}}
										color={'inherit'}
									>
										{item.title}<ArrowForwardIosIcon sx={{ position: "absolute", right: 0}}/>
									</Button>
									{item.id === active &&
										<>
											{subTopics && subTopics?.map((item,index) => (
												<Grid item xs={2} sm={8} md={8} key={item.id}>
													{(subTopics?.length || subTopicsStorage?.length) && !loadingSubTopics ?
														<>
															{item?.enabled || item?.enabled === undefined ?
																<Button key={item.id} onClick={handleClickSubTopic} has_sons={item?.has_sons || subTopicsStorage[item.id]?.has_sons}
																	topic_title={item?.title || subTopicsStorage[item.id]?.title} subtopic_id={item?.id || subTopicsStorage[item.id]?.id}
																	channel_id={item?.channel_id || subTopicsStorage[item.id]?.channel_id} buttontype='button' variant="outlined" size="small"
																	sx={{minWidth:'100%', justifyContent: 'left', textTransform:'initial', fontSize:'15px',borderTop:  index != 0  ? 'none' :null, borderTop : item?.enabled || index === 0 ? null : 'none'}} color={'inherit'}
																	subtopic_code={item?.code || subTopicsStorage[item.id]?.code}
																>
																	{item.title}<ArrowForwardIosIcon sx={{ position: "absolute", right: 0}}/>
																</Button>
																:
																null
															}
														</>

														:
														<Box sx={{ width: '100%', display: "flex", justifyContent: "center" }}><CircularProgress /></Box>
													}
												</Grid>

											))}
										</>

									}
								</Grid>

							))}
						</>
					:
						<>
							{
								data && data?.map(item => (
									<>
										{item?.enabled || item?.enabled === undefined ?
											<Grid item xs={2} sm={8} md={4} key={item.id}>
												<Button key={item.id} onClick={handleClickTopic} has_sons={item?.has_sons}
													topic_title={item?.title} topic_code={item.code} topic_id={item?.id} channel_id={item?.channel_id}
													buttontype='button' variant="outlined" size="large"
													sx={{justifyContent:'left !important', wordWrap:'anywhere', minWidth:'100%',fontSize:'18px'}} color='inherit'
												>
													{item.title}<ArrowForwardIosIcon sx={{ position: "absolute", right: 0}}/>
												</Button>
											</Grid>
											:
											null
										}
									</>
								))
							}
						</>
					}
				</Grid>
				:
				<Box sx={{ width: '100%', display: "flex", justifyContent: "center" }}><CircularProgress /></Box>
			}
	</Box>
	)

}
