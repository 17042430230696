import * as React from 'react';

import logo from '../../src/assets/images/logo/large.svg'
import { useNavigate } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';



export default function Logo() {
	const navigate = useNavigate();
	const path = window.location.href
	const maxWidth = useMediaQuery('(max-width:390px)')

	return <img alt="" src={logo} onClick={() => path.includes('landing') ? null : navigate('/home')} style={{cursor:'pointer', minWidth:'110px', width : maxWidth ? '110px' : null}}/>
}
