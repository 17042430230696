import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { clearNotification } from '../redux/reducers/notifications';

const Notifications = () => {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();

	const snackbarData = useSelector((state) => state.notifications.notifications);

	const message = snackbarData.message
	const variant = snackbarData.variant

	useEffect(() => {
		if (message) {
			enqueueSnackbar(message, { variant });
			dispatch(clearNotification())
		}
	}, [message, variant]);
}

export default Notifications
