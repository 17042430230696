import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
export default function BasicDatePicker(props) {
	const { id, label, value, onChange, size } = props
	const [time, setTime] = useState("");
	useEffect(() => {
		if (value) {
			setTime(dayjs(value).format("MM/DD/YYYY"))
		}
		else {
			setTime("")
		}
	}, [value]);
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'it'}>
			<DatePicker
				label={label}
				inputFormat="DD/MM/YYYY"
				value={time || null}
				onChange={(newValue) => {
					let date = dayjs(newValue)
					if (date.isValid()) {
						date = date.format("YYYY-MM-DD")
					} else date = ''
					onChange({ target: { name: id, value: date } })
				}}
				renderInput={(params) => {
					return (
						<TextField
							// defaultValue={null}
							// value={time || null}
							inputProps={{ placeholder: "DD/MM/YYYY" }}
							size={size}
							style={{ width: "100%" }}
							{...params}
							error={props.error || false}
							helperText={props.helperText || ""}
						/>
					)
				}
				}
			/>
		</LocalizationProvider>
	);
}
