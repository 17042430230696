import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '../components/Button/Index'
import { getTopic} from '../redux/reducers/auth.js';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getTopics, getLoadingTopics,  getLoadingSubTopics, getSubTopic,getSubTopics} from '../redux/reducers/auth.js';
import { getLookups } from '../redux/reducers/lookups.js';
import { sendTickets, getLoading, sendEmail } from '../redux/reducers/form.js';
import { useNavigate, useParams} from 'react-router-dom'
import Typography from '@mui/material/Typography';
import Field from '../components/Field/Index.js'
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import {verifyPartitaIva} from '../utils/piva.js'
import {verifyCodiceFiscale} from '../utils/cf.js'
import FileUpload from './FileUpload';
import Breadcrumbs from '../components/Breadcrumbs/Index';
import CircularProgress from '@mui/material/CircularProgress';
import ReCAPTCHA from "react-google-recaptcha";

function ConvertHtmlString({ htmlString }) {
	return (
		<div dangerouslySetInnerHTML={{ __html: htmlString }}></div>
	);
}

export default function FormContainer (){
	const params = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const loading = useSelector(getLoading)
	const [formValues, setFormValues] = useState({customer_code:'',codice_fiscale_partita_iva:'',shop_order_number:'',fullname:'',email:'',
		telephone:'',abstract:'',privacy:false, files: [], email_verification: ''
	})
	const [files, setFiles] = useState([]);
	const [filesId, setFilesId] = useState([]);
	const [queuecompleted, setQueuecompleted] = useState(true)
	const [showMessage, setShowMessag] = useState('')
	const [warning ,setWarning] = useState(false)
	const [changeEmail,setChangeEmail] = useState(true)
	const [status, setStatus] = useState('')
	const [token,setToken] = useState({recaptcha_v2:'', recaptcha_v3:''})
	const [recaptcha,setRecaptcha] = useState(true)

	const recaptchaRef = React.useRef();

	const channelcode = params.channelcode
	const topic_code = params.topic_code
	const subtopic_code = params.subtopic_code
	const savedData = JSON.parse(localStorage.getItem('savedData'))

	useEffect(() => {
		recaptchaRef.current.executeAsync()

		if(params.channelcode){
			dispatch(getTopic(params.channelcode))
		}
		if(channelcode && topic_code){
			dispatch(getSubTopic({topic_code, channelcode}))
		}
		if(channelcode && topic_code && subtopic_code){

			localStorage.setItem('subtopic_code', subtopic_code);
		}

		if(savedData){
			parseSavedData()
		}

	}, [params])

	const channels = useSelector(getLookups)
	const topics =  useSelector(getTopics)
	const subtopics =  useSelector(getSubTopics)
	const loadingTopics = useSelector(getLoadingTopics)
	const loadingSubTopics = useSelector(getLoadingSubTopics)

	const channel_id = channels.length != 0 && params.channelcode ?  channels?.find((channel)=> channel.code === params.channelcode ).id : null
	const topic_id = !loadingTopics && topics.length != 0  && params.topic_code ? topics?.find((topic)=> topic.code === params.topic_code ).id : null
	const subtopic_id = !loadingSubTopics && subtopics.length != 0  && params.subtopic_code ? subtopics?.find((subtopic)=> subtopic.code === params.subtopic_code ).id : null
	const topic_has_codice_cliente = !loadingTopics && topics.length != 0  && params.topic_code ? topics?.find((topic)=> topic.code === params.topic_code ).has_codice_cliente : null
	const subtopic_has_codice_cliente = !loadingSubTopics && subtopics.length != 0  && params.subtopic_code ?
		subtopics?.find((subtopic)=> subtopic.code === params.subtopic_code).has_codice_cliente : null

	const topic_has_number_order = !loadingTopics && topics.length != 0  && params.topic_code ? topics?.find((topic)=> topic.code === params.topic_code ).has_number_order : null
	const subtitleSubtopic = !loadingSubTopics && subtopics.length != 0  && params.subtopic_code ? subtopics?.find((subtopic)=> subtopic.code === params.subtopic_code ).subtitle : null
	const topic_subtitle = !loadingTopics && topics.length != 0 && params.topic_code ? topics?.find((topic) => topic.code === params.topic_code).subtitle : null
	const topicHasUidRemoteCode = !loadingTopics && topics.length != 0 && params.topic_code ? topics?.find((topic) => topic.code === params.topic_code).uid_remote_code : null
	const subtopicHasUidRemoteCode =  !loadingSubTopics && subtopics.length != 0  && params.subtopic_code ? subtopics?.find((subtopic)=> subtopic.code === params.subtopic_code ).uid_remote_code : null
	const topic_courtesy = !loadingTopics && topics.length != 0 && params.topic_code ? topics?.find((topic) => topic.code === params.topic_code).courtesy : null

	const requestValues = {
		...formValues,
		files: filesId,
		channel_id: channel_id,
		topic_id: topic_id,
		subtopic_id: subtopic_id,
		recaptcha_v2:token.recaptcha_v2,
		recaptcha_v3:token.recaptcha_v3,
		controlUser: !savedData || !changeEmail ? false : true,
	}

	const [error, setErrors] = useState({customer_code:'',codice_fiscale_partita_iva:'',shop_order_number:'',fullname:'',email:'',
		telephone:'',abstract:'',privacy:''
	})
	const [emailError,setEmailError] = useState('')

	const textStyle = {
		fontSize:'13.5px',
		position:'relative',
		top:'3px',
		color: error.privacy ? "#d32f2f" : "gray",
	}

	function parseSavedData(){
		if(savedData){
			setFormValues({...formValues,
				customer_code: savedData.customer_code,
				fullname : savedData.fullname,
				email: savedData.email,
				codice_fiscale_partita_iva: savedData.codice_fiscale_partita_iva,
				telephone: savedData.telephone,
				email_verification: savedData.email_verification
			})
		}
	}

	function sendTicket(){
		let errors = validationForm(formValues,['customer_code','codice_fiscale_partita_iva','shop_order_number','fullname','email','telephone','abstract','privacy']);
		if (queuecompleted && Object.values(errors).length === 0 && (status != "failed" || !status)){
			dispatch(sendTickets(requestValues)).then((response) => {
				if(response.payload.status === 'success'){
					navigate(window.location.pathname + '/summary')

					localStorage.setItem('allSendValues', JSON.stringify(requestValues))
					localStorage.setItem('filesName', JSON.stringify(files))
					localStorage.setItem('savedData', JSON.stringify({
						customer_code: formValues.customer_code,
						fullname : formValues.fullname,
						email: formValues.email,
						codice_fiscale_partita_iva: formValues.codice_fiscale_partita_iva,
						telephone: formValues.telephone,
						email_verification: formValues.email_verification
					}))
				}else if(!response.payload.allowSubmit){
					setRecaptcha(false)
				}
			})
			setShowMessag('')

		}else{
			if (!queuecompleted) {
				setShowMessag('È in corso il caricamento di un file')
			}else if(status === "failed"){
				setShowMessag(`C'è un errore nella verifica dell email`)
				setErrors({...errors,email:emailError})
			}else{
				setShowMessag('I campi obbligatori non sono compilati')
				setErrors(errors)
			}

		}
	}

	function onChange(e){

		if(e.target.name ==='email'){
			setChangeEmail(true)
			setWarning(false)
			setFormValues({controlUser:false})
		}

		let initialState = {
			...formValues,[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
		}

		if(e.target.type === 'checkbox'){
			setFormValues({...initialState, [e.target.name]: e.target.checked})
		}else if(e.target.name === "codice_fiscale_partita_iva"){
			setFormValues({...initialState, [e.target.name]: e.target.value.toUpperCase()})
		}else{
			setFormValues({...initialState, [e.target.name]: e.target.value})
		}

		const errors = validationForm(initialState,[e.target.name])
		const valueError = Object.values(errors)

		let newError = {
			...error,
			[e.target.name] : valueError[0]
		}

		if(e.target.name === 'customer_code' || e.target.name === 'codice_fiscale_partita_iva'){
			if(errors.codice_fiscale_partita_iva === ''){
				setErrors({...newError,codice_fiscale_partita_iva : ''})
			}else if(errors.customer_code === ''){
				setErrors({...newError,customer_code : ''})
			}else if(errors.customer_code === '' && errors.codice_fiscale_partita_iva != ''){
				setErrors({...newError , codice_fiscale_partita_iva : errors.codice_fiscale_partita_iva})
			}else if(errors.customer_code != '' && errors.codice_fiscale_partita_iva === ''){
				setErrors({...newError , customer_code : errors.codice_fiscale_partita_iva})
			}else {
				setErrors({...newError , customer_code : errors.customer_code, codice_fiscale_partita_iva : errors.codice_fiscale_partita_iva})
			}
		}else{
			setErrors({...newError , [e.target.name] : valueError[0]})
		}
	}

	function onChangeCAPTCHAv2(token){
		setToken({recaptcha_v2:token})
	}

	function onChangeCAPTCHAv3(token){
		setToken({recaptcha_v3:token})
	}

	function verificationEmail(email){
		if( email && ( error.email === undefined || error.email === '') && changeEmail ){
			dispatch(sendEmail({email:email, recaptcha_v3:token.recaptcha_v3})).then((response) => {
				setChangeEmail(false)
				setStatus(response.payload.result)
				setFormValues({...formValues,email_verification : response.payload.result})
				setRecaptcha(response.payload.recaptcha_response)
				if(!response.payload.recaptcha_response){
					setToken({recaptcha_v3:''})
				}
				if(response.payload.result){
					setErrors({email: response.payload.resultmessage})
					setEmailError(response.payload.resultmessage)
					if(response.payload.result === 'warning'){
						setWarning(true)
					}
					if(response.payload.result === 'success'){
						setWarning('success')
					}
				}
			})
		}
	}

	function validationForm(values,code=[]){
		const errors = {}
		if(topic_has_number_order){
			if(code.includes('shop_order_number')){
				const regex = /^[0-9]{7,8}$/i
				if (values.shop_order_number === '') {
					errors.shop_order_number = "Campo obbligatorio"
				}else if(!regex.test(values.shop_order_number)){
					errors.shop_order_number = "Numero ordine deve contenere almeno 7 numeri e non più di 8"
				}
			}
		}else if(topic_has_codice_cliente || subtopic_has_codice_cliente){
			if(code.includes('customer_code') && code.includes('codice_fiscale_partita_iva')){
				const regex = /^[0-9]{3,10}$/i
				const pivaValid = verifyPartitaIva(values.codice_fiscale_partita_iva)
				const fiscalValid = verifyCodiceFiscale(values.codice_fiscale_partita_iva)

				if(values.customer_code === '' && values.codice_fiscale_partita_iva === '') {
					errors.customer_code = "Campo obbligatorio"
				}else if(!regex.test(values.customer_code) && values.customer_code != '') {
					errors.customer_code = "Codice cliente deve contenere almeno 3 numeri e non più di 10"
				}

				if(values.codice_fiscale_partita_iva === '' && values.customer_code === '') {
					errors.codice_fiscale_partita_iva = "Campo obbligatorio"
				}else if(!pivaValid && !fiscalValid && values.codice_fiscale_partita_iva != ''){
					errors.codice_fiscale_partita_iva = "Partita iva o Codice fiscale non è corretto"
				}

			}else if(code.includes('customer_code') || code.includes('codice_fiscale_partita_iva')) {
				const regex = /^[0-9]{3,10}$/i
				const pivaValid = verifyPartitaIva(values.codice_fiscale_partita_iva)
				const fiscalValid = verifyCodiceFiscale(values.codice_fiscale_partita_iva)

				if (values.customer_code === '' && values.codice_fiscale_partita_iva === '') {
					errors.customer_code = "Campo obbligatorio"
				}
				if (values.codice_fiscale_partita_iva === '' &&  values.customer_code === '') {
					errors.codice_fiscale_partita_iva = "Campo obbligatorio"
				}
				if(!regex.test(values.customer_code) && values.customer_code != '') {
					errors.customer_code = "Codice cliente deve contenere almeno 3 numeri e non più di 10"

				}
				if(!pivaValid && !fiscalValid && values.codice_fiscale_partita_iva != ''){
					errors.codice_fiscale_partita_iva = "Partita iva o Codice fiscale non è corretto"

				}
			}
		}else if(!topic_has_codice_cliente || !subtopic_has_codice_cliente && !topic_has_number_order){
			if(code.includes('codice_fiscale_partita_iva')){
				const pivaValid = verifyPartitaIva(values.codice_fiscale_partita_iva)
				const fiscalValid = verifyCodiceFiscale(values.codice_fiscale_partita_iva)

				if (values.codice_fiscale_partita_iva === '') {
					errors.codice_fiscale_partita_iva = "Campo obbligatorio"
				}else if(!pivaValid && !fiscalValid ){
					errors.codice_fiscale_partita_iva = "Partita iva o Codice fiscale non è corretto"
				}
			}
		}
		if(code.includes('telephone')){
			const regex = /^\s*(?:\+\d{6,})?(\d{7,})?\s*$/i

			if (values.telephone === '') {
				errors.telephone = "Campo obbligatorio"
			}else if(!regex.test(values.telephone)){
				errors.telephone = "Numero di telefono non è scritto correttamente"
			}
		}if(code.includes('email')){
			const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/i

			if (values.email === '') {
					errors.email = "Campo obbligatorio"
			}else if(!regex.test(values.email)) {
				errors.email = "Email non è scritto correttamente"
			}
		}if(code.includes('fullname')){
			if (values.fullname === '') {
				errors.fullname = "Campo obbligatorio"
			}
		}if(code.includes('abstract')){
			if (values.abstract === '') {
				errors.abstract = "Campo obbligatorio"
			}
		}if(code.includes('privacy')){
			if (values.privacy === false) {
				errors.privacy = "Campo obbligatorio"
			}
		}
		return errors
	}

	const onDropzoneFiles = (filesD) =>{

		setQueuecompleted(Boolean(filesD.queuecompleted))
		if (showMessage){
			setShowMessag(false)
		}

		if (filesD.acceptedFiles == [] || filesD.acceptedFiles){
			setFiles(filesD.acceptedFiles)
		}
	}

	const onDropzoneFilesId = (filesId) =>{
		if (filesId){
			setFilesId(filesId)
		}
	}

	return (
		<Box sx={{ flexGrow: 1}}>
			<Breadcrumbs/>
			{!loadingSubTopics ?
				<>
					{topicHasUidRemoteCode != '' || subtopicHasUidRemoteCode != null ?
						<>
							<Typography sx={{ mt: 3,mb:1, fontWeight:400, fontSize: '18px', color:'#484747' }}>
								{"Compila il modulo con i tuoi dati"}
							</Typography>
							<Grid container rowSpacing={3} sx={{mt:0}} columnSpacing={{ xs: 1, sm: 2, md: 10}}>
								{topic_has_codice_cliente || subtopic_has_codice_cliente ?
									<Grid item xs={6} >
										<Field
											error={!error.customer_code  ? false : true}
											type='text'
											required={error.codice_fiscale_partita_iva != 'Campo obbligatorio' && error.codice_fiscale_partita_iva != '' ? false : true}
											label='Codice cliente'
											name="customer_code"
											id="customer_code"
											onBlur={onChange}
											helperText={error.customer_code}
											defaultValue={savedData?.customer_code}
										/>
									</Grid>
									: null
								}
								{topic_has_number_order ?
									<Grid item xs={6}>
										<Field
											error={!error.shop_order_number  ? false : true}
											type='text'
											required
											label='Numero ordine Shop'
											name="shop_order_number"
											id="shop_order_number"
											onBlur={onChange}
											helperText={error.shop_order_number}
											defaultValue={savedData?.shop_order_number}
										/>
									</Grid>
									:null
								}
								{!topic_has_number_order &&
									<Grid item xs={6}>
										<Field
											error={!error.codice_fiscale_partita_iva  ? false : true}
											type='text'
											required={(error.customer_code != 'Campo obbligatorio' && error.customer_code != '')|| topic_has_number_order ? false : true}
											label='Codice Fiscale o Partita Iva '
											name="codice_fiscale_partita_iva"
											id="codice_fiscale_partita_iva"
											onBlur={onChange}
											helperText={error.codice_fiscale_partita_iva}
											defaultValue={savedData?.codice_fiscale_partita_iva}
											inputProps={{ style: { textTransform: "uppercase" } }}
										/>
									</Grid>
								}
								<Grid item xs={12}>
									<Field
										error={!error.fullname  ? false : true}
										type='text'
										required
										label='Nominativo/Ragione sociale'
										name="fullname"
										id="fullname"
										onBlur={onChange}
										helperText={error.fullname}
										defaultValue={savedData?.fullname}
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										error={!error.email || !changeEmail ? false : true}
										type='text'
										required
										label='Indirizzo e-mail'
										name="email"
										id="email"
										onChange={onChange}
										helperText={<span style={{color: warning === 'success' ? '#009f09': warning ? '#ec8808' : '#d32f2f'}}>{error.email}</span>}
										onBlur={() => verificationEmail(formValues.email)}
										defaultValue={savedData?.email}
										inputProps={{ style: { textTransform: "lowercase" } }}
									/>
								</Grid>
								<Grid item xs={6}>
									<Field
										error={!error.telephone  ? false : true}
										type='text'
										required
										label='Telefono'
										name="telephone"
										id="telephone"
										onBlur={onChange}
										helperText={error.telephone}
										defaultValue={savedData?.telephone}
									/>
								</Grid>
							</Grid>
							<Typography sx={{ mt: 4, fontWeight:400, fontSize: '18px', color:'#484747' }}>
								{`Descrivi nel campo seguente la tua richiesta`}
							</Typography>
							{topic_subtitle &&
							<Typography sx={{ mt: 3, fontWeight: 400, fontSize: '14px', color: '#484747' }}>
									<ConvertHtmlString htmlString={topic_subtitle} />
							</Typography>
							}
							{subtitleSubtopic &&
							<Typography sx={{ mt: 3, fontWeight:400, fontSize: '14px', color:'#484747' }}>
								<ConvertHtmlString htmlString={subtitleSubtopic} />
							</Typography>
							}
							<Grid container rowSpacing={3} sx={{mt:1}} columnSpacing={{ xs: 1, sm: 2, md: 3}}>
								<Grid item xs={12} >
									<Field
										error={!error.abstract  ? false : true}
										type='text'
										required
										multiline
										label='Descrizione'
										name="abstract"
										id="abstract"
										onBlur={onChange}
										helperText={error.abstract}
									/>
								</Grid>
								<Grid item xs={12}>
									<FileUpload onDropzoneFiles={onDropzoneFiles} onDropzoneFilesId={onDropzoneFilesId}/>
								</Grid>
								<Grid item xs={12}>
									<FormControlLabel
										control={
											<Field
												type='checkbox'
												required
												name="privacy"
												id="privacy"
												onChange={onChange}
												// helperText={error.privacy}
											/>
										}
									/>{
										<span style={textStyle}>
											{'Ho '}
											<Link underline="hover" target="_blank" href="https://www.wolterskluwer.com/it-it/privacy-cookies">
												{'letto e accetto l’informativa '}
											</Link>
											{'ai sensi dell’art 13 D.lgs 196/03 e del Regolamento UE 679/2016'}
										</span>
									}
									<Typography sx={{ml:'25px', fontSize:'13.5px'}}>{'Inviando i dati acconsenti affinchè Wolters Kluwer Italia S.r.l. utilizzi gli stessi o altri eventualmente già in nostro possesso per fornirti supporto sulla tua richiesta.'}</Typography>
								</Grid>
								<Grid item xs={4} >
									{!recaptcha && <ReCAPTCHA
										sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2}
										onChange={onChangeCAPTCHAv2}
									/>}
								</Grid>
							</Grid>

							<Button  onClick={sendTicket} loading={loading} buttontype='loading' variant="contained" fullWidth size='large'
								sx={{mt:5, backgroundColor:'#007ac3'}}
							>
								{'Invia Richiesta'}
							</Button>
							{showMessage != '' ?
								<Typography sx={{mt:'10px',color:'#d32f2f', fontSize:'13px'}}>{showMessage}</Typography>
								: null
							}
						</>
						:
							<Typography sx={{ mt: 10, fontWeight:400, fontSize: '20px', color:'#484747' }}>
								<ConvertHtmlString htmlString={topic_courtesy} />
							</Typography>
						}
				</>
				: <Box sx={{ width: '100%', display: "flex", justifyContent: "center", mt:20 }}><CircularProgress /></Box>
			}
			{recaptcha && <ReCAPTCHA
				ref={recaptchaRef}
				sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V3}
				onChange={onChangeCAPTCHAv3}
				size="invisible"
				render="explicit"
			/>}
		</Box>
	)
}
