import * as React from 'react';
import { Dropzone } from "dropzone";
import PublishIcon from '@mui/icons-material/Publish';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './FileUpload.css';
import Api from '../config/api'
import Typography from '@mui/material/Typography';
// import {lookup} from 'mime-types';

const customMessage =
{
	// width: "150px",
	display: "block",
	justifyContent: "center",
	alignItems: "center",
	// background: "#85b3db4a",
	// border: "1px solid #85b3db8f",
	padding: "4px",
	borderRadius: "10px",
	// fontWeight: "bold",
	cursor: "pointer"
}
export default function FileUpload({ onDropzoneFiles, onDropzoneFilesId}) {
	let params = useParams();
	const channelcode = params.channelcode
	const topictitle = params.topictitle
	const baseURL = `${process.env.REACT_APP_BACKEND_URL}`
	const localAPI = baseURL + "/api/v1/upload"
	const API = window.location.hostname === "localhost" ? `${localAPI}` : `${window.location.origin}/api/v1/`
	const XSRFString = document.cookie;

	const deleteRequest = (attachment_id) =>{
		Api.delete(`/removefile/${attachment_id}`)
	}

	const [contentMimeType, setContentMimeType] = useState("")

	useEffect(() => {
		if (document.getElementById('my-awesome-dropzone')) {
			let fileData = {}
			const dropzone = new Dropzone("#my-awesome-dropzone",
				{
					headers: {
						'X-Requested-With': 'XMLHttpRequest',
						'X-XSRF-TOKEN': decodeURIComponent(XSRFString.split("XSRF-TOKEN=")[1]),
					},
					url: localAPI,
					// autoProcessQueue: false,
					addRemoveLinks: true,
					chunking: true,
					chunkSize: 200000,
					maxFilesize: 5,
					maxFiles: 5,
					acceptedFiles: "image/*,.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .eml, .msg, .zip",
					// previewTemplate: document.querySelector('#template-preview').innerHTML,
					withCredentials: true,
					paramName: 'file',
					method: 'POST',
					credentials: 'same-origin',
					parallelUploads: 10,
					init: function () {
						this.on("addedfile", file => {
							fileData.queuecompleted = false
							onDropzoneFiles(fileData)
							const fileReader = new FileReader()
							fileReader.onload = () => {
								const arrayBuffer = fileReader.result;
								const fileData = new Uint8Array(arrayBuffer)
								if (fileData.length >= 7) {
									const firstSevenBytes = fileData.slice(0, 7);
									const isTextFile = firstSevenBytes.every((byte) => byte >= 32 && byte <= 126);

									if (firstSevenBytes[0] === 0x50 && firstSevenBytes[1] === 0x4B && firstSevenBytes[2] === 0x03 && firstSevenBytes[3] === 0x04 && file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
									) {
										setContentMimeType('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') 
									} else if (
										firstSevenBytes[0] === 0x50 && firstSevenBytes[1] === 0x4B && firstSevenBytes[2] === 0x03 && firstSevenBytes[3] === 0x04 && file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
									) {
										setContentMimeType('application/vnd.openxmlformats-officedocument.wordprocessingml.document')
									} else if (
										firstSevenBytes[0] === 0xD0 && firstSevenBytes[1] === 0xCF && firstSevenBytes[2] === 0x11 && firstSevenBytes[3] === 0xE0 && file.type === "application/vnd.ms-excel"
									) {
										setContentMimeType('application/vnd.ms-excel')
									}
									else if (firstSevenBytes[0] === 0x50 && firstSevenBytes[1] === 0x4B && firstSevenBytes[2] === 0x03 && firstSevenBytes[3] === 0x04 && file.type === "application/zip"
									) {
										setContentMimeType('application/zip')
									} else if (firstSevenBytes[0] === 0xFF && firstSevenBytes[1] === 0xD8 && firstSevenBytes[2] === 0xFF) {
										setContentMimeType('image/jpeg')
									} else if (
										firstSevenBytes[0] === 0x25 && firstSevenBytes[1] === 0x50 && firstSevenBytes[2] === 0x44 && firstSevenBytes[3] === 0x46
									) {
										setContentMimeType('application/pdf')
									} else if (firstSevenBytes[0] === 0x89 && firstSevenBytes[1] === 0x50 && firstSevenBytes[2] === 0x4E && firstSevenBytes[3] === 0x47) {
										setContentMimeType('image/png')
									} else if (firstSevenBytes[0] === 0xD0 && firstSevenBytes[1] === 0xCF && firstSevenBytes[2] === 0x11 && file.type === "application/vnd.ms-powerpoint"
									) {
										return 'application/vnd.ms-powerpoint';
									} else if (firstSevenBytes[0] === 0x50 && firstSevenBytes[1] === 0x4B && firstSevenBytes[2] === 0x03 && firstSevenBytes[3] === 0x04 && file.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation"
									) {
										setContentMimeType('application/vnd.openxmlformats-officedocument.presentationml.presentation')
									} else if (firstSevenBytes[0] === 0xD0 && firstSevenBytes[1] === 0xCF && firstSevenBytes[2] === 0x11 && firstSevenBytes[3] === 0xE0 && file.type === "application/msword"
									) {
										setContentMimeType('application/msword')
									} else if (firstSevenBytes[0] === 0xD0 && firstSevenBytes[1] === 0xCF && firstSevenBytes[2] === 0x11 && firstSevenBytes[3] === 0xE0
									) {
										setContentMimeType('application/vnd.ms-outlook')
									} else {
										setContentMimeType("Unknown")
										this.removeFile(file);
									}
								}
							}
							fileReader.readAsArrayBuffer(file)
						})
						this.on("sending", function (file, xhr, formData) {
							fileData.uploadingFiles= dropzone.getUploadingFiles().length;
							fileData.queuecompleted = false;
							onDropzoneFiles(fileData)
						});
						this.on("queuecomplete", function (file) {
							// alert("All files have uploaded ");
							fileData.uploadingFiles =  dropzone.getUploadingFiles().length;
							fileData.queuecompleted = true
							onDropzoneFiles(fileData)
						});
					},
					sending: function (file, xhr, formData){
						document.getElementsByClassName("dz-remove").forEach(element => {
							if (element.innerHTML === "Cancel upload") {
								element.innerHTML = `<i style="font-size:18px;color:gray;padding: 0px;margin-left: 5px"class="fa">&#xf00d;</i>`
							}
						});
					},
					success: function (file, response, message) {
						const fileId = response?.result?.file_id
						const fileName = response?.result?.original_filename

						let acceptedFilesNames = []

						dropzone.getAcceptedFiles().forEach(element => { acceptedFilesNames.push(element.name) })
						fileData.acceptedFiles = acceptedFilesNames;

						onDropzoneFiles(fileData)
						onDropzoneFilesId(files => [...files,fileId])
						const removeFile = (file_id, original_filename) => {
							const attachment_id = file_id
							deleteRequest(attachment_id)
							let acceptedFilesNames = []
							dropzone.getAcceptedFiles().forEach(element => { acceptedFilesNames.push(element.name) })
							fileData.acceptedFiles = acceptedFilesNames;
							onDropzoneFiles(fileData)
						}


						// dropzone.on("sending", function (file, xhr, formData) { });


						document.getElementsByClassName("dz-remove").forEach(element => {
							// let fileName = file.upload.filename;
							dropzone.on("complete", function (file) {
								if (element.innerHTML === "Remove file" ){
									element.innerHTML = `<i class="fa fa-trash test" id="deleteFile" style="color:gray;padding: 0px;margin-left: 5px"></i>`
									element.addEventListener("click", (event) => {
										const fileId = response?.result?.file_id
										removeFile(fileId)
									})
								}
							})
						});

						dropzone.on("error", function (file) {
							dropzone.removeFile(file);
						});
					},
				});

		}
	}, [])


	// function processEvent() {
	// 	const dropzone = document.getElementById('my-awesome-dropzone')
	// 	dropzone.dropzone.processQueue();
	// }
	return (
		<div style={{border: "1px solid black", padding: "15px"}}>
			<div className="wrapper" style={{ margin: "5px" }} id="my-awesome-dropzone">
				< form action="/file-upload"
					className="dropzone test"
					id="my-awesome-dropzone"
				>
					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexFlow: "wrap"}}>
						<label style={{color: "grey"}}>
							Se necessario puoi caricare file trascinandoli qui sopra o cliccando sul tasto "carica file".<br />
							<p style={{fontSize: "12px"}}>
							Puoi caricare fino a 5 allegati di 5MB ciascuno. <br />
							Sono consentiti i pi&ugrave; comuni formati di lavoro (pdf, immagini, documenti office, archivi, email)
							</p>
							{contentMimeType == "Unknown" ? <em style={{color: "red", fontSize: "15px"}}>THE CONTENT OF THE FILE YOU JUST TRIED TO UPLOAD IS NOT APPROPRIATE!</em> : null}
						</label>
						<div className="dz-message" data-dz-message><span className="customMessage" style={customMessage}>
							<div style={{ display: "flex", justifyContent: "center" }}><PublishIcon fontSize="large" /></div><Typography sx={{ fontSize: "1 rem", padding: "0px" }}>Carica file</Typography></span>
						</div>
					</div>

				</form >
			</div>
		</div>

	)
}
