import React, {useEffect, useState} from 'react';
import Footer from '../components/Footer';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme/index';
import Header from '../components/Header/Index';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';


export default function HomeLayout({ children }) {
	const isChrome = navigator.userAgent.indexOf('Chrome') !== -1;
	const maxWidth = useMediaQuery('(max-width:900px)')
	const height = window.innerHeight - 200
	const height2 = window.innerHeight + 600

	const checkMaintenance = process.env.REACT_APP_MAINTENANCE

	return (
		<>
			<ThemeProvider theme={theme}>
				<Header/>
				<Box >
					<Container component="main" sx={{ mb: 3, mt:10, minHeight: '83vh'}}>
					{checkMaintenance === 'false' ?
						<>
							<Typography sx={{ mt: 3, mb: 3, fontWeight:500, fontSize: '21px', color:'#626161' }} gutterBottom>{`Inserisci la tua richiesta di assistenza`}
								<Box sx={{float:'right'}}>
									<span cx="20" cy="20" r="20" style={{fontSize: '16px', backgroundColor:'#29acfb', borderRadius:'15px',paddingInline: '9px', paddingBlock: isChrome ? '5px' : '4px', color:'white'}}>1</span>&nbsp;&nbsp;&nbsp;
									<span cx="20" cy="20" r="20" style={{fontSize: '16px', backgroundColor:'#9f9f9fd9', borderRadius:'15px',paddingInline: '9px', paddingBlock: isChrome ? '5px' : '4px', color:'white'}}>2</span>&nbsp;&nbsp;&nbsp;
									<span cx="20" cy="20" r="20" style={{fontSize: '16px', backgroundColor:'#9f9f9fd9', borderRadius:'15px',paddingInline: '9px', paddingBlock: isChrome ? '5px' : '4px', color:'white'}}>3</span>
								</Box>
							</Typography>
							<Typography sx={{ mt: 3, mb: 2, fontWeight:300, fontSize: '18px', color:'#484747' }}>{`Indica la natura della tua richiesta scegliendo fra una delle tipologie disponibili`}</Typography>
							<br/>
							<Grid container > {children} </Grid>
						</>
						:
						<Typography sx={{ mt: 40, ml:20, fontWeight:500, fontSize: '21px'}} >
							{'Gentile utente,'}<br/>
							{'il form web per contattare il nostro Servizio Clienti è momentaneamente in manutenzione.'}<br/><br/>
							{'La preghiamo di utilizzare in alternativa i seguenti canali:'}<br/><br/>
							{'email: servizio.clienti@wolterskluwer.com'}<br/>
							{'telefono: (+39) 02 824761'}
						</Typography>
					}
					</Container>
					<Footer/>
    			</Box>
			</ThemeProvider>
		</>
	)
}
