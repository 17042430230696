import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import "./i18Init"
import Notifications from './components/Notifications';

import './assets/scss/index.scss';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import Loading from './components/Loading';

import CssBaseline from '@mui/material/CssBaseline';

import { SnackbarProvider } from 'notistack';

const container = document.getElementById('root');
const root = createRoot(container);
try {
	if (process && ["stage", "prod","production"].includes(process.env.REACT_APP_ENV)) {
		console.log("%cWatch out this is danger zone!", "color: red; font-size: 25px");
		console.log("%c“In a gentle way you can shake the world.” ― Mahatma Gandhi", "color: red; font-size: 25px");
		console.log("%cUse this Application for good things", "color: red; font-size: 25px");
	}
} catch (error) { }

root.render(
	<Provider store={store}>
		<BrowserRouter>
			<SnackbarProvider maxSnack={3}>
				<Suspense fallback={<Loading />}>
					<CssBaseline />
					<App />
					<Notifications />
				</Suspense>
			</SnackbarProvider>
		</BrowserRouter>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
