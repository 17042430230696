/*
Austria 	AT U99999999 	Lettera U + 8 cifre
Belgio 	BE 0999999999 	la cifra 0 poi 9 cifre
Bulgaria 	BG 999999999 	9 o 10 cifre
Croazia 	HR 99999999999 	11 cifre
Repubblica Ceca 	CZ 999999999 	8, 9 o 10 cifre
Cipro 	CY 99999999L 	9 caratteri
Danimarca 	DK 99999999 	8 cifre (l'ultima cifra è un numero di controllo)
Estonia 	EE 999999999 	9 cifre
Finlandia 	FI 99999999 	8 cifre (l'ultima cifra è un numero di controllo)
Francia 	FR 99999999999 	11 cifre (il primo e secondo carattere può essere una lettera)
Germania 	DE 999999999 	9 cifre
Grecia 	EL 999999999 	9 cifre (l'ultima cifra è un numero di controllo)
Ungheria 	HU 99999999 	8 cifre
Irlanda 	IE 9999999XX 	7 cifre seguite da 1 o 2 lettere
Italia 	IT 99999999999 	11 cifre (l'ultima cifra è un numero di controllo)
Lettonia 	LV 99999999999 	11 cifre
Lituania 	LT 999999999 	9 o 12 cifre
Luxembourg 	LU 99999999 	8 cifre
Malta 	MT 99999999 	8 cifre
Paesi Bassi 	NL 999999999B99 	11 cifre + lettera B
Polonia 	PL 9999999999 	10 cifre (l'ultima cifra è un numero di controllo)
Portogallo 	PT 999999999 	9 cifre (l'ultima cifra è un numero di controllo)
Romania 	RO 9999999999 	2-10 cifre (l'ultima cifra è un numero di controllo)
Slovacchia 	SK 9999999999 	10 cifre
Slovenia 	SI 99999999 	8 cifre (l'ultima cifra è un numero di controllo)
Spagna 	ESX9999999X 	9 cifre (il primo o l'ultimo carattere può essere una lettera)
Svezia 	SE 999999999901 	12 cifre (le ultime due cifre sono sempre 01)
Regno Unito 	GB 999999999 	9 cifre
 * */
export function CheckPartitaIvaEU(partitaiva){
	let country='';
	let pattern='';
	let reg = partitaiva.toUpperCase().match('^([A-Z]{2})[0-9A-Z]{2,12}$');
	if(reg && reg.length==2 && reg[1]!=''){
		//prova a riconoscere il prefisso ed applicare una regexp specifica per singola nazione
		switch(reg[1]){
			case 'AT': country=reg[1]; pattern='ATU[0-9]{8}'; break;
			case 'BE': country=reg[1]; pattern='BE0[0-9]{9}'; break;
			case 'BG': country=reg[1]; pattern='BG[0-9]{9,10}'; break;
			case 'HR': country=reg[1]; pattern='HR[0-9]{11}'; break;
			case 'CZ': country=reg[1]; pattern='CZ[0-9]{8,10}'; break;
			case 'CY': country=reg[1]; pattern='CY[A-Z0-9]{9}'; break;
			case 'DK': country=reg[1]; pattern='DK[0-9]{8}'; break;
			case 'EE': country=reg[1]; pattern='EE[0-9]{9}'; break;
			case 'FI': country=reg[1]; pattern='FI[0-9]{8}'; break;
			case 'FR': country=reg[1]; pattern='FR[0-9A-Z]{2}[0-9]{9}'; break;
			case 'DE': country=reg[1]; pattern='DE[0-9]{9}'; break;
			case 'EL': country=reg[1]; pattern='EL[0-9]{9}'; break;
			case 'HU': country=reg[1]; pattern='HU[0-9]{8}'; break;
			case 'IE': country=reg[1]; pattern='HU[0-9]{7}[A-Z]{1,2}'; break;

			case 'IT': country=reg[1]; pattern='IT[0-9]{11}'; return false; break;

			case 'LV': country=reg[1]; pattern='LV[0-9]{11}'; break;
			case 'LT': country=reg[1]; pattern='LT[0-9]{9,12}'; break;
			case 'LU': country=reg[1]; pattern='LU[0-9]{8}'; break;
			case 'MT': country=reg[1]; pattern='MT[0-9]{8}'; break;
			case 'NL': country=reg[1]; pattern='NL[0-9]{9}B[0-9]{2}'; break;
			case 'PL': country=reg[1]; pattern='PL[0-9]{10}'; break;
			case 'PT': country=reg[1]; pattern='PT[0-9]{9}'; break;
			case 'RO': country=reg[1]; pattern='RO[0-9]{2-10}'; break;
			case 'SK': country=reg[1]; pattern='SK[0-9]{10}'; break;
			case 'SI': country=reg[1]; pattern='SI[0-9]{8}'; break;
			case 'ES': country=reg[1]; pattern='ES[0-9A-Z][0-9]{7}[0-9A-Z]'; break;
			case 'SE': country=reg[1]; pattern='SE[0-9]{10}01'; break;
			case 'GB': country=reg[1]; pattern='GB[0-9]{9}'; break;

		}
		if(pattern!='' && partitaiva.toUpperCase().match('^'+pattern+'$')){
			return true;
		}else
			return false;
	}
}

export function verifyPartitaIva(partitaiva,country){
	if(!country) country='IT';
	//integrazione per riconoscere una partita iva comunitaria
	//try to recognize
	if(country!='IT' || country=='EU'){
		//se riconosce la valida e restituisce direttamente true, naturalmente il caso IT non viene considerato
		if(CheckPartitaIvaEU(partitaiva)) return true;

	}

	// normalizziamo la cifra
	//while (partitaiva.length<11) { partitaiva = '0'+partitaiva; }
	//valuta se contiene solo 11 caratteri numerici
	if (!partitaiva.match('^([0-9]{11})$')) return false;

	var somma = 0;
	var ListaPari = new Array ();
	ListaPari[0] = 0;
	ListaPari[1] = 2;
	ListaPari[2] = 4;
	ListaPari[3] = 6;
	ListaPari[4] = 8;
	ListaPari[5] = 1;
	ListaPari[6] = 3;
	ListaPari[7] = 5;
	ListaPari[8] = 7;
	ListaPari[9] = 9;
	var s ='';
	for (let k=0; k<11;k++){
		s = partitaiva.substr(k,1);
		let i = parseInt(s);
		if (k % 2 == 1)
		var x = ListaPari[i];
		else
		x = i;
		somma += x;
	}
	return ((somma % 10 == 0) && (somma != 0));
}
